import { CLink } from '@/CLink';
import { PlainBtn } from '@/buttons';
import { smTxt } from '@/layout/styles/Typography';
import { flexSpace, flexStart } from '@/layout/styles/classes';
import { ReactComponent as DeleteIcon } from '@a/icons/delete.svg';
import { ReactComponent as DiscountIcon } from '@a/icons/discount.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ShopifyContext } from '@s/context/ShopifyContext';
import { Fragment, memo, useContext } from 'react';
import { CheckoutLineItem } from 'shopify-buy';
import { AddToCart } from './AddToCart';

const Box = styled.div<{ cartPage?: boolean }>`
    ${flexSpace};
    padding-bottom: ${({ cartPage }) => (cartPage ? '32px' : '16px')};

    :not(:last-of-type) {
        margin-bottom: ${({ cartPage }) => (cartPage ? '32px' : '16px')};
        border-bottom: 1px solid ${({ theme }) => theme.colors.gray2};
    }

    > div:first-of-type {
        flex-shrink: 0;
        width: 100px;
        height: 131px;
        background-color: ${({ theme }) => theme.colors.yellow1};
        border-radius: 16px;
        margin-right: ${({ cartPage }) => (cartPage ? '32px' : '16px')};

        > img {
            width: 100%;
            height: 100%;
        }
    }

    // delete button
    > button:last-of-type {
        path {
            transition: fill 0.3s ease-in-out;
            fill: ${({ theme }) => theme.colors.blue8};
        }

        :hover {
            path {
                fill: ${({ theme }) => theme.colors.blue4};
            }
        }

        :focus-visible {
            path {
                fill: ${({ theme }) => theme.colors.blue4};
            }
        }
    }

    @media (min-width: 768px) {
        ${({ cartPage }) =>
            cartPage &&
            css`
                > div:first-of-type {
                    width: 130px;
                    height: 171px;
                }
                > button:last-of-type > svg {
                    width: 32px;
                    height: 34px;
                }

                // Info component
                > div:nth-of-type(2) {
                    ${flexStart};
                    align-items: center;
                    gap: 48px;
                    margin-right: 64px;
                    width: 100%;

                    > a:first-of-type {
                        font-size: 1.5rem;
                        font-weight: 600;
                        min-width: 150px;
                    }

                    // price element
                    > p:first-of-type {
                        font-size: 1.25rem;
                        margin: 0;
                        order: 1;
                        margin-left: auto;
                        font-weight: 500;

                        > span:nth-of-type(2) {
                            display: block;
                            margin: 8px 0;
                            font-weight: 600;
                        }
                    }

                    // quantity input
                    > div > div {
                        padding: 14px 20px;
                        width: 138px;
                    }
                }
            `};
    }
`;

const Info = styled.div`
    margin-right: auto;

    > a:first-of-type {
        color: ${({ theme }) => theme.colors.blue8};
        font-size: 1.25rem;
        transition: color 0.3s ease-in-out;

        :hover,
        :focus {
            color: ${({ theme }) => theme.colors.blue4};
        }
    }

    > p:first-of-type {
        color: ${({ theme }) => theme.colors.blue4};
        margin: 2px 0 16px;

        > span {
            :nth-of-type(2) {
                margin-left: 8px;
            }

            :last-of-type:not(:first-of-type) {
                ${smTxt};
                ${flexStart};
                align-items: center;
                color: ${({ theme }) => theme.colors.gray3};
                text-transform: capitalize;

                > svg {
                    margin-right: 4px;
                }
            }
        }
    }

    > div > div {
        padding: 11px 15px;
    }
`;

const CartItemDetails = ({ item }: { item: CheckoutLineItem }) => {
    const { checkout, removeLineItem, loading } = useContext(ShopifyContext);

    const handleRemove = () => {
        removeLineItem(checkout.id, item.id);
    };

    const productLink = item.variant?.product?.handle
        ? `/products/${item.variant?.product?.handle}/`
        : '';

    const price =
        typeof item.variant?.price.amount === 'string'
            ? parseFloat(item.variant?.price?.amount).toFixed(2)
            : item.variant?.price?.amount.toFixed(2);

    const hasDiscount = item.discountAllocations.length > 0;

    let discountedPrice = item.variant?.price.amount;
    if (
        typeof item.variant?.price.amount === 'string' &&
        typeof item.discountAllocations[0]?.allocatedAmount?.amount === 'string'
    ) {
        discountedPrice =
            Number(item.variant?.price.amount) -
            Number(item.discountAllocations[0]?.allocatedAmount?.amount);
    } else if (
        typeof item.variant?.price.amount === 'number' &&
        typeof item.discountAllocations[0]?.allocatedAmount?.amount === 'number'
    ) {
        discountedPrice =
            item.variant?.price.amount - item.discountAllocations[0]?.allocatedAmount?.amount;
    }

    //types are wrong amount is a string and title does exist
    const discount = hasDiscount
        ? {
              amount: parseFloat(item.discountAllocations[0]?.allocatedAmount?.amount).toFixed(2),
              title: item.discountAllocations[0]?.discountApplication?.title,
              price: parseFloat(discountedPrice)?.toFixed(2),
          }
        : {};

    return (
        <Fragment>
            <Info>
                <CLink to={productLink}>{item.title}</CLink>
                <p>
                    <span style={{ textDecoration: hasDiscount ? 'line-through' : 'unset' }}>
                        ${price}
                    </span>

                    {hasDiscount ? (
                        <Fragment>
                            <span>${discount.price}</span>
                            <span>
                                <DiscountIcon /> {discount.title} (-${discount.amount})
                            </span>
                        </Fragment>
                    ) : (
                        ''
                    )}
                </p>
                <AddToCart cart variantId={item.variant?.id as string} />
            </Info>
            <PlainBtn
                aria-label="remove this item from cart"
                onClick={handleRemove}
                disabled={loading}
            >
                <DeleteIcon width={20} height={21} />
            </PlainBtn>
        </Fragment>
    );
};

export const croppedImageUrlBuilder = (
    baseUrl: string,
    width: number,
    height: number,
    format = 'png'
) => {
    // eslint-disable-next-line
    let [basename, version] = baseUrl?.split(`?`);
    const dot = basename.lastIndexOf(`.`);
    let ext = ``;
    let suffix = ``;

    try {
        if (dot !== -1) {
            ext = basename.slice(dot + 1);
            basename = basename.slice(0, dot);
        }
        if (format === ext || format === `auto`) {
            suffix = `.${ext}`;
        } else {
            suffix = `.${ext}.${format}`;
        }
    } catch (e) {
        console.log(e);
    }

    return `${basename}_${width}x${height}_crop_center${suffix}?${version}`;
};

function Item({ item, cartPage }: { item: CheckoutLineItem; cartPage?: boolean }) {
    // GatsbyImage avoided as it was flickering on new render
    const itemImage = {
        ...item.variant?.image,
        src: item.variant?.image?.src || item.variant?.image?.url,
    };
    const imageWidth = cartPage ? 130 : 100;
    const imageHeight = cartPage ? 172 : 131;

    const image = itemImage.src && {
        url: croppedImageUrlBuilder(itemImage.src as string, imageWidth, imageHeight),
        alt: item.variant?.image.altText || item.title,
    };

    return (
        <Box cartPage={cartPage}>
            <div>
                {image && (
                    <img src={image.url} alt={image.alt} width={imageWidth} height={imageHeight} />
                )}
            </div>

            <CartItemDetails item={item} />
        </Box>
    );
}

export const CartItem = memo(Item);
