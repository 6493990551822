import { CLink } from '@/CLink';
import { ArrowCircleBtn, TxtBtn } from '@/buttons';
import { ShopifyContext } from '@s/context/ShopifyContext';
import { useContext, useEffect, useState } from 'react';

export const AddToCartSingle = ({ variantId }: { variantId: string }) => {
    const { checkout, addVariantToCart, loading } = useContext(ShopifyContext);
    const [inCart, setInCart] = useState(false);

    const handleAdd = () => {
        addVariantToCart(variantId, 1);
    };

    useEffect(() => {
        const cartItem =
            checkout.lineItems?.length > 0
                ? checkout.lineItems.filter(item => item?.variant?.id === variantId)
                : [];

        setInCart(cartItem.length > 0);
    }, [checkout.lineItems, variantId]);

    return inCart ? (
        <TxtBtn as={CLink} to="/cart/">
            View Cart
        </TxtBtn>
    ) : (
        <ArrowCircleBtn onClick={handleAdd} disabled={loading}>
            add to cart
        </ArrowCircleBtn>
    );
};
