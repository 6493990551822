import { PlainBtn, SecondaryBtn } from '@/buttons';
import { flexSpace, flexStart } from '@/layout/styles/classes';
import { ReactComponent as CartIcon } from '@a/icons/cart.svg';
import styled from '@emotion/styled';
import { ShopifyContext } from '@s/context/ShopifyContext';
import debounce from 'lodash.debounce';
import { FormEvent, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { CheckoutLineItem } from 'shopify-buy';
import { ShopPayBtn } from './ShopPayBtn';

const Box = styled.div`
    ${flexStart};
    flex-wrap: wrap;
    align-items: center;
    gap: 20px;
`;

const Btns = styled.div`
    ${flexSpace};
    width: 120px;
    border-radius: 61px;
    border: 1px solid rgba(0, 0, 0, 0.16);
    padding: 14px 20px;
    transition: border-color 0.3s ease-in-out;
    flex-shrink: 0;

    :focus-within {
        border-color: rgba(0, 0, 0, 0.3);
    }

    > button {
        width: 18px;
        height: 18px;
        transition: background-color 0.3s ease-in-out;
        font-weight: 400;
        font-size: 1.25rem;
        flex-shrink: 0;

        :hover {
            background-color: rgba(0, 0, 0, 0.06);
        }

        :focus-visible {
            background-color: rgba(0, 0, 0, 0.06);
        }
    }

    > input {
        border: none;
        outline: none;
        width: 100%;
        max-width: 3.2ch;
        -moz-appearance: textfield;
        text-align: center;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
        }

        :disabled {
            pointer-events: none;
            opacity: 0.7;
        }
    }

    @media (min-width: 1280px) {
        width: 138px;
    }
`;

type Props = {
    variantId: string;
    cart?: boolean; //means its cart
    price?: number | string;
};

export const AddToCart = ({ variantId, cart, price }: Props) => {
    const { checkout, addVariantToCart, loading, updateLineItem } = useContext(ShopifyContext);

    // to check if current item is already added to cart
    const [cartItem, setCartItem] = useState<CheckoutLineItem | null>(null);

    useEffect(() => {
        setCartItem(
            checkout.lineItems?.length > 0
                ? checkout.lineItems.filter(item => item?.variant?.id === variantId)[0]
                : null
        );
    }, [checkout.lineItems, variantId]);

    // state for input element
    const [productQuantity, setQuantity] = useState(1);

    useEffect(() => {
        setQuantity(cartItem?.quantity || 1);
    }, [cartItem]);

    const updateItemQuantity = (itemId: string, value: number) => {
        updateLineItem(checkout.id, itemId, value);
    };
    const handleAdd = () => {
        if (cartItem) {
            updateItemQuantity(cartItem.id, cartItem.quantity + productQuantity);
        } else {
            addVariantToCart(variantId as string, productQuantity); // variantId is not passed from cart where item is already added
        }
    };

    const debounceUpdateQuantity = useCallback(
        debounce((value: number) => updateItemQuantity(cartItem?.id as string, value), 300),
        [cartItem]
    );

    useEffect(() => {
        return () => {
            if (loading) {
                debounceUpdateQuantity.cancel();
            }
        };
    }, [debounceUpdateQuantity, loading]);

    const handleQuantity = (value: number) => {
        setQuantity(value);
        if (cart) {
            setQuantity(value);
            debounceUpdateQuantity(value);
        }
    };

    const decrement = () => handleQuantity(Math.max(1, productQuantity - 1));

    const increment = () => handleQuantity(productQuantity + 1);

    const inputChange = (event: FormEvent<HTMLInputElement>) =>
        handleQuantity(
            Number(event.currentTarget.value) === 0 ? 1 : Number(event.currentTarget.value)
        );

    return (
        <Box>
            <Btns>
                <PlainBtn onClick={decrement} disabled={productQuantity === 1 || loading}>
                    &ndash;
                </PlainBtn>
                <input
                    type="number"
                    min="1"
                    value={productQuantity}
                    onChange={inputChange}
                    pattern="[1-9]+"
                    disabled={loading}
                />
                <PlainBtn onClick={increment} disabled={loading}>
                    +
                </PlainBtn>
            </Btns>
            {cart ? (
                ''
            ) : (
                <Fragment>
                    <SecondaryBtn onClick={handleAdd} disabled={loading}>
                        <CartIcon /> {cartItem ? `add ${productQuantity} more` : 'add to cart'}
                    </SecondaryBtn>
                    {price ? (
                        <ShopPayBtn
                            variantId={variantId}
                            price={price}
                            quantity={productQuantity}
                        />
                    ) : (
                        ''
                    )}
                </Fragment>
            )}
        </Box>
    );
};
