import { ReactNode, createContext, useEffect, useState } from 'react';
import Client, { Checkout } from 'shopify-buy';

const client = Client.buildClient({
    domain: '9e5132.myshopify.com',
    storefrontAccessToken: 'a165fa656e9974816b31877f804b9286',
    apiVersion: '2023-07',
});

type ContextType = {
    loading: boolean;
    checkout: Checkout;
    addVariantToCart: (variantId: string, quantity: number) => Promise<void | Checkout>;
    removeLineItem: (checkoutId: string, lineItemId: string) => Promise<void | Checkout>;
    updateLineItem: (
        checkoutId: string,
        lineItemId: string,
        quantity: number
    ) => Promise<void | Checkout>;
    addProductsToCart: (
        items: {
            variantId: string;
            quantity: number;
        }[]
    ) => Promise<void | Checkout>;
    addedToCart: boolean;
};

export const ShopifyContext = createContext<ContextType>({} as ContextType);

const isBrowser = typeof window !== `undefined`;
const localStorageKey = `shopify_checkout_id`;

export const ShopifyProvider = ({ children }: { children: ReactNode }) => {
    const [checkout, setCheckout] = useState<Checkout>({} as Checkout);
    const [loading, setLoading] = useState(false);
    const [addedToCart, setAddedToCart] = useState(false);

    const setCheckoutItem = (checkout: Checkout) => {
        if (isBrowser) {
            localStorage.setItem(localStorageKey, checkout.id);
        }

        setCheckout(checkout);
    };

    useEffect(() => {
        (async () => {
            const existingCheckoutId = isBrowser ? localStorage.getItem(localStorageKey) : null;

            if (existingCheckoutId && existingCheckoutId !== `null`) {
                try {
                    await client.checkout.fetch(existingCheckoutId).then(checkout => {
                        if (!checkout.completedAt) {
                            setCheckoutItem(checkout);
                            return;
                        }
                    });
                } catch (e) {
                    console.log(e);
                    localStorage.setItem(localStorageKey, '');
                }
            } else {
                try {
                    await client.checkout.create().then(checkout => setCheckoutItem(checkout));
                } catch (e) {
                    console.log(e);
                }
            }
        })();
    }, []);

    const addVariantToCart = async (variantId: string, quantity: number) => {
        setLoading(true);
        const checkoutId = checkout.id;

        const lineItemsToAdd = [
            {
                variantId,
                quantity,
            },
        ];

        let res;

        try {
            res = await client.checkout.addLineItems(checkoutId, lineItemsToAdd);
            setCheckout(res);
            setLoading(false);
            setAddedToCart(true);
            setTimeout(() => setAddedToCart(false), 1000);
        } catch (err) {
            setLoading(false);
            console.log(err);
        }

        return res;
    };

    const removeLineItem = async (checkoutId: string, lineItemId: string) => {
        setLoading(true);

        let res;

        try {
            res = await client.checkout.removeLineItems(checkoutId, [lineItemId]);
            setCheckout(res);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }

        return res;
    };

    const updateLineItem = async (checkoutId: string, lineItemId: string, quantity: number) => {
        setLoading(true);

        const lineItemsToUpdate = [{ id: lineItemId, quantity }];

        let res;

        try {
            res = await client.checkout.updateLineItems(checkoutId, lineItemsToUpdate);
            setCheckout(res);
            setLoading(false);
        } catch (err) {
            console.log(err);
        }

        return res;
    };

    const addProductsToCart = async (
        items: {
            variantId: string;
            quantity: number;
        }[]
    ) => {
        setLoading(true);
        const checkoutId = checkout.id;

        let res;

        try {
            res = await client.checkout.addLineItems(checkoutId, items);
            setCheckout(res);
            setLoading(false);
            setAddedToCart(true);
            setTimeout(() => setAddedToCart(false), 1000);
        } catch (err) {
            setLoading(false);
            console.log(err);
        }

        return res;
    };

    return (
        <ShopifyContext.Provider
            value={{
                addVariantToCart,
                removeLineItem,
                updateLineItem,
                addProductsToCart,
                checkout,
                loading,
                addedToCart,
            }}
        >
            {children}
        </ShopifyContext.Provider>
    );
};
