import { GraphQLClient, gql } from 'graphql-request';
import {
    Customer,
    CustomerAccessToken,
    CustomerAccessTokenCreateInput,
    CustomerCreateInput,
    CustomerUpdateInput,
} from 'shopify-buy';

const client = new GraphQLClient('https://9e5132.myshopify.com/api/2023-07/graphql.json', {
    headers: {
        'X-Shopify-Storefront-Access-Token': 'a165fa656e9974816b31877f804b9286',
    },
});

const localStorageCustomerTokenKey = `smartazminerals_customer_token`;

function saveToken(token: string) {
    localStorage.setItem(localStorageCustomerTokenKey, token);
}

type CustomerTokenGetType =
    | {
          accessToken: string;
          expiresAt: string;
      }
    | undefined;

function customerTokenGet(): CustomerTokenGetType {
    const token = localStorage.getItem(localStorageCustomerTokenKey);
    if (token) {
        return JSON.parse(token);
    } else return undefined;
}

function customerTokenDelete() {
    const token = customerTokenGet();
    if (token?.accessToken) {
        localStorage.removeItem(localStorageCustomerTokenKey);
        try {
            client.request(
                gql`
                    mutation customerAccessTokenDelete($customerAccessToken: String!) {
                        customerAccessTokenDelete(customerAccessToken: $customerAccessToken) {
                            userErrors {
                                message
                            }
                        }
                    }
                `,
                { customerAccessToken: token.accessToken }
            );
        } catch (e) {
            console.log(e);
        }
    }
}

function handleFetchError(e: any) {
    if (e?.response?.errors?.length > 0) {
        return {
            error: e?.response?.errors[0]?.message,
        };
    }
    return {
        error: 'Unknown error, please try again later.',
    };
}

type CustomerGetType = {
    customer: null | Customer;
};

function customerGet(customerAccessToken: string): Promise<CustomerGetType> {
    return client.request(
        gql`
            query ($customerAccessToken: String!) {
                customer(customerAccessToken: $customerAccessToken) {
                    id
                    firstName
                    lastName
                    phone
                    email
                    numberOfOrders
                    defaultAddress {
                        address1
                        address2
                        zip
                        city
                        province
                        country
                    }
                    orders(first: 30) {
                        nodes {
                            processedAt
                            orderNumber
                            fulfillmentStatus
                            financialStatus
                            totalPrice {
                                amount
                            }
                            originalTotalPrice {
                                amount
                            }
                            totalShippingPrice {
                                amount
                            }
                            lineItems(first: 30) {
                                nodes {
                                    quantity
                                    title
                                    variant {
                                        id
                                        price {
                                            amount
                                        }
                                        product {
                                            handle
                                        }
                                        image {
                                            altText
                                            height
                                            id
                                            url
                                            width
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `,
        { customerAccessToken }
    );
}

type CustomerCreateType = {
    error?: string;
    email?: string;
};

async function customerCreate(input: CustomerCreateInput): Promise<CustomerCreateType> {
    type Response = {
        customerCreate: {
            customer: {
                email: string;
            };
            customerUserErrors: {
                message: string;
            }[];
        };
    };
    try {
        const res: Response = await client.request(
            gql`
                mutation customerCreate($input: CustomerCreateInput!) {
                    customerCreate(input: $input) {
                        customer {
                            email
                        }
                        customerUserErrors {
                            message
                        }
                    }
                }
            `,
            { input }
        );
        if (res.customerCreate.customerUserErrors.length > 0) {
            return {
                error: res.customerCreate.customerUserErrors[0].message,
            };
        } else return res.customerCreate.customer;
    } catch (e) {
        return handleFetchError(e);
    }
}

type CustomerRecoverType = {
    error?: string;
    success?: boolean;
};

async function customerRecover(email: string): Promise<CustomerRecoverType> {
    type Response = {
        customerRecover: {
            customerUserErrors: {
                message: string;
            }[];
        };
    };
    try {
        const res: Response = await client.request(
            gql`
                mutation customerRecover($email: String!) {
                    customerRecover(email: $email) {
                        customerUserErrors {
                            message
                        }
                    }
                }
            `,
            { email }
        );
        if (res.customerRecover.customerUserErrors.length > 0) {
            return {
                error: res.customerRecover.customerUserErrors[0].message,
            };
        } else
            return {
                success: true,
            };
    } catch (e) {
        return handleFetchError(e);
    }
}

type CustomerResetByUrlType = {
    error?: string;
    accessToken?: string;
    expiresAt?: string;
};

async function customerResetByUrl(
    password: string,
    resetUrl: string
): Promise<CustomerResetByUrlType> {
    type Response = {
        customerResetByUrl: {
            customerAccessToken: CustomerAccessToken;
            customerUserErrors: {
                message: string;
            }[];
        };
    };
    try {
        const res: Response = await client.request(
            gql`
                mutation customerResetByUrl($password: String!, $resetUrl: URL!) {
                    customerResetByUrl(password: $password, resetUrl: $resetUrl) {
                        customerAccessToken {
                            accessToken
                            expiresAt
                        }
                        customerUserErrors {
                            message
                        }
                    }
                }
            `,
            { password, resetUrl }
        );
        if (res.customerResetByUrl.customerUserErrors.length > 0) {
            return {
                error: res.customerResetByUrl.customerUserErrors[0].message,
            };
        } else {
            saveToken(JSON.stringify(res.customerResetByUrl.customerAccessToken));
            return res.customerResetByUrl.customerAccessToken;
        }
    } catch (e) {
        return handleFetchError(e);
    }
}

async function customerUpdate(customer: CustomerUpdateInput, customerAccessToken: string) {
    type Response = {
        customerUpdate: {
            customer: Customer;
            customerAccessToken: CustomerAccessToken;
            customerUserErrors: {
                message: string;
            }[];
        };
    };
    try {
        const res: Response = await client.request(
            gql`
                mutation customerUpdate(
                    $customer: CustomerUpdateInput!
                    $customerAccessToken: String!
                ) {
                    customerUpdate(customer: $customer, customerAccessToken: $customerAccessToken) {
                        customer {
                            id
                            firstName
                            lastName
                            phone
                            defaultAddress {
                                address1
                                address2
                                zip
                                city
                                province
                                country
                            }
                        }
                        customerAccessToken {
                            accessToken
                            expiresAt
                        }
                        customerUserErrors {
                            message
                        }
                    }
                }
            `,
            { customer, customerAccessToken }
        );
        if (res.customerUpdate.customerUserErrors.length > 0) {
            return {
                error: res.customerUpdate.customerUserErrors[0].message,
            };
        } else {
            saveToken(JSON.stringify(res.customerUpdate.customerAccessToken));
            return {
                customer: res.customerUpdate.customer,
                customerAccessToken: res.customerUpdate.customerAccessToken,
            };
        }
    } catch (e) {
        return handleFetchError(e);
    }
}

type CustomerAccessTokenCreateType = {
    error?: string;
    accessToken?: string;
    expiresAt?: string;
};

async function customerAccessTokenCreate(
    input: CustomerAccessTokenCreateInput
): Promise<CustomerAccessTokenCreateType> {
    type Response = {
        customerAccessTokenCreate: {
            customerAccessToken: CustomerAccessToken;
            customerUserErrors: {
                message: string;
            }[];
        };
    };
    try {
        const res: Response = await client.request(
            gql`
                mutation customerAccessTokenCreate($input: CustomerAccessTokenCreateInput!) {
                    customerAccessTokenCreate(input: $input) {
                        customerAccessToken {
                            accessToken
                            expiresAt
                        }
                        customerUserErrors {
                            message
                        }
                    }
                }
            `,
            { input }
        );
        if (res.customerAccessTokenCreate.customerUserErrors.length > 0) {
            return {
                error: res.customerAccessTokenCreate.customerUserErrors[0].message,
            };
        } else {
            saveToken(JSON.stringify(res.customerAccessTokenCreate.customerAccessToken));
            return res.customerAccessTokenCreate.customerAccessToken;
        }
    } catch (e) {
        return handleFetchError(e);
    }
}

async function customerAccessTokenRenew(customerAccessToken: string) {
    type Response = {
        customerAccessTokenRenew: {
            customerAccessToken: CustomerAccessToken;
            userErrors: {
                message: string;
            }[];
        };
    };
    try {
        const res: Response = await client.request(
            gql`
                mutation customerAccessTokenRenew($customerAccessToken: String!) {
                    customerAccessTokenRenew(customerAccessToken: $customerAccessToken) {
                        customerAccessToken {
                            accessToken
                            expiresAt
                        }
                        userErrors {
                            message
                        }
                    }
                }
            `,
            { customerAccessToken }
        );
        if (res.customerAccessTokenRenew.userErrors.length > 0) {
            return {
                error: res.customerAccessTokenRenew.userErrors[0].message,
            };
        } else {
            saveToken(JSON.stringify(res.customerAccessTokenRenew.customerAccessToken));

            return res.customerAccessTokenRenew.customerAccessToken;
        }
    } catch (e) {
        return handleFetchError(e);
    }
}

async function customerAssociateCheckout(checkoutId: string, customerAccessToken: string) {
    type Response = {
        checkoutCustomerAssociateV2: {
            checkout: {
                webUrl: string;
            };
            checkoutUserErrors: {
                message: string;
            }[];
        };
    };
    try {
        const res: Response = await client.request(
            gql`
                mutation checkoutCustomerAssociateV2(
                    $checkoutId: ID!
                    $customerAccessToken: String!
                ) {
                    checkoutCustomerAssociateV2(
                        checkoutId: $checkoutId
                        customerAccessToken: $customerAccessToken
                    ) {
                        checkout {
                            webUrl
                        }
                        checkoutUserErrors {
                            message
                        }
                    }
                }
            `,
            { checkoutId, customerAccessToken }
        );
        if (res.checkoutCustomerAssociateV2.checkoutUserErrors.length > 0) {
            return {
                error: res.checkoutCustomerAssociateV2.checkoutUserErrors[0].message,
            };
        } else {
            return res.checkoutCustomerAssociateV2.checkout?.webUrl;
        }
    } catch (e) {
        return handleFetchError(e);
    }
}

export {
    customerAccessTokenCreate,
    customerAccessTokenRenew,
    customerAssociateCheckout,
    customerCreate,
    customerGet,
    customerRecover,
    customerResetByUrl,
    customerTokenDelete,
    customerTokenGet,
    customerUpdate,
};
