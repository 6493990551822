import { DialogClose } from '@/DialogPrimitive';
import { PlainBtn, SecondaryBtn } from '@/buttons';
import { lgTxt } from '@/layout/styles/Typography';
import { flexCenter } from '@/layout/styles/classes';
import { ReactComponent as CartIcon } from '@a/icons/cart.svg';
import { ReactComponent as CloseIcon } from '@a/icons/close.svg';
import { ReactComponent as PillsIcon } from '@a/icons/pills.svg';
import styled from '@emotion/styled';
import { ShopifyContext } from '@s/context/ShopifyContext';
import { Fragment, useContext, useState } from 'react';
import { CartItem } from './CartItem';
import { customerAssociateCheckout, customerTokenGet } from './customer';

const Info = styled.div`
    display: grid;
    grid-template-rows: auto 1fr auto;
    height: 100%;

    //cart empty text
    > p {
        ${lgTxt};
        font-weight: 500;
        margin: 0;
        text-align: center;
    }
`;

const Top = styled.div`
    margin-bottom: 32px;

    > button {
        margin-left: auto;
        display: block;
        margin-bottom: 8px;
    }

    > p {
        ${flexCenter};
        margin: 0;

        > svg {
            margin-right: 7px;
        }
    }
    > h2 {
        color: ${({ theme }) => theme.colors.blue8};
        margin: 4px 0 0;
        text-align: center;
    }

    @media (min-height: 800px) {
        margin-bottom: 64px;
    }
`;

const ItemsDisplay = styled.div`
    overflow-y: auto;
    padding-right: 12px;
`;

const Cta = styled.div<{ cartPage?: boolean }>`
    ${flexCenter};
    margin-top: 32px;
    flex-direction: column;

    > p {
        ${lgTxt};
        margin: 0 0 8px;
        margin-bottom: ${({ cartPage }) => (cartPage ? '32px' : '8px')};

        span {
            color: ${({ theme }) => theme.colors.blue4};
        }
    }

    > button {
        > svg {
            margin-right: 7px;
        }
    }

    @media (min-height: 800px) {
        margin-top: 64px;
    }
`;

const Checkout = ({ id, url, loading }: { id: string; url: string; loading: boolean }) => {
    const [fetching, setFetching] = useState(false);

    const handleCheckout = async () => {
        setFetching(true);
        const token = customerTokenGet();
        if (token) {
            await customerAssociateCheckout(id, token.accessToken);
        }
        setFetching(false);
        window.open(url);
    };
    return (
        <SecondaryBtn onClick={handleCheckout} disabled={loading || fetching}>
            <CartIcon /> Checkout
        </SecondaryBtn>
    );
};

export const CartContent = ({ cartPage }: { cartPage?: boolean }) => {
    const { checkout, loading } = useContext(ShopifyContext);

    const emptyCart = checkout?.lineItems?.length === 0;

    return (
        <Info>
            <Top>
                {cartPage ? (
                    ''
                ) : (
                    <DialogClose>
                        <PlainBtn aria-label="close cart dialog">
                            <CloseIcon />
                        </PlainBtn>
                    </DialogClose>
                )}
                <p className="tagline">
                    <PillsIcon /> checkout
                </p>
                <h2>Your Cart</h2>
            </Top>

            {emptyCart ? (
                <p>Your cart is empty</p>
            ) : (
                <Fragment>
                    <ItemsDisplay>
                        {checkout.lineItems?.map(item => (
                            <CartItem key={item.id} item={item} cartPage={cartPage} />
                        ))}
                    </ItemsDisplay>
                    <Cta cartPage={cartPage}>
                        <p>
                            Subtotal: <span>${checkout.totalPrice?.amount}</span>
                        </p>
                        <Checkout id={checkout.id} url={checkout.webUrl} loading={loading} />
                    </Cta>
                </Fragment>
            )}
        </Info>
    );
};
