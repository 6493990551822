import { Dialog, DialogContent, DialogOpen } from '@/DialogPrimitive';
import { PlainBtn } from '@/buttons';
import { flexCenter } from '@/layout/styles/classes';
import { ReactComponent as CartIcon } from '@a/icons/cart.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ShopifyContext } from '@s/context/ShopifyContext';
import { useContext } from 'react';
import { CartContent } from './CartContent';

const contentStyles = css`
    background-color: #fff;
    height: 100%;
    padding: 16px;
    right: 0;
    top: 0;
    width: 100vw;
    max-width: 480px;
    transition: transform 0.3s ease-in-out;

    @media (min-height: 800px) {
        padding-top: 32px;
        padding-bottom: 32px;
    }

    &[data-state='open'] {
        animation: slideIn 300ms ease-in;
    }

    &[data-state='closed'] {
        animation: slideOut 300ms ease-out;
    }

    @keyframes slideIn {
        from {
            transform: translateX(100vw);
        }
        to {
            transform: translateX(0);
        }
    }

    @keyframes slideOut {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(100vw);
        }
    }
`;

const CartBtn = styled(PlainBtn)`
    width: 32px;
    height: 32px;
    position: relative;
    margin-right: 32px;

    path {
        transition: fill 0.3s ease-in-out;
        fill: ${({ theme }) => theme.colors.blue6};
    }

    > span {
        ${flexCenter};
        font-size: 0.7rem;
        position: absolute;
        bottom: -6px;
        right: -3px;
        height: 18px;
        width: max-content;
        min-width: 18px;
        border-radius: 18px;
        font-weight: 500;
        background-color: ${({ theme }) => theme.colors.blue4};
        color: ${({ theme }) => theme.colors.white};
        line-height: 1;
        aspect-ratio: 1/1;
        transition: background-color 0.3s ease-in-out;
    }

    :hover {
        path {
            fill: ${({ theme }) => theme.colors.blue4};
        }

        > span {
            background-color: ${({ theme }) => theme.colors.blue6};
        }
    }

    :focus-visible {
        path {
            fill: ${({ theme }) => theme.colors.blue4};
        }

        > span {
            background-color: ${({ theme }) => theme.colors.blue6};
        }
    }

    @media (min-width: 840px) {
        margin-right: 0;
    }
`;

export const Cart = () => {
    const { checkout } = useContext(ShopifyContext);

    const emptyCart = checkout?.lineItems?.length === 0;

    const items = !emptyCart ? checkout.lineItems : [];

    const quantity = emptyCart
        ? 0
        : items?.reduce((total, item) => {
              return total + item.quantity;
          }, 0);
    return (
        <Dialog>
            <DialogOpen>
                <CartBtn aria-label="open cart dialog">
                    <CartIcon width={32} height={32} />
                    {!quantity ? '' : <span>{quantity}</span>}
                </CartBtn>
            </DialogOpen>

            <DialogContent contentStyles={contentStyles} title="cart dialog">
                <CartContent />
            </DialogContent>
        </Dialog>
    );
};
