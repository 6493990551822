import { Fragment, useEffect } from 'react';

type Props = {
    variantId: string;
    price: string | number;
    quantity: number;
};
export const ShopPayBtn = ({ variantId, price, quantity }: Props) => {
    const termPrice = (Number(price) / 4).toFixed(2);
    const id = variantId.split('gid://shopify/ProductVariant/')[1];

    useEffect(() => {
        const id = 'shop-pay-info-script';
        if (document.getElementById(id) === null) {
            const script = document.createElement('script');
            script.setAttribute(
                'src',
                'https://cdn.shopify.com/shopifycloud/shop-js/v1.0/client.js'
            );
            script.setAttribute('id', id);
            document.body.appendChild(script);
        }
    }, []);

    return (
        <Fragment>
            <shop-pay-button
                store-url="https://9e5132.myshopify.com"
                variants={`${id}:${quantity}`}
            ></shop-pay-button>
            <shopify-payment-terms
                variant-id={id}
                shopify-meta={`{"type":"product","variants":[{"id":${id},"price_per_term":"$${termPrice}","full_price":"$${price}","eligible":true,"available":true}],"min_price":"$50.00","max_price":"$30,000.00","financing_plans":[{"min_price":"$50.00","max_price":"$149.99","terms":[{"apr":0,"loan_type":"split_pay","installments_count":4}]},{"min_price":"$150.00","max_price":"$999.99","terms":[{"apr":0,"loan_type":"split_pay","installments_count":4},{"apr":15,"loan_type":"interest","installments_count":6},{"apr":15,"loan_type":"interest","installments_count":12}]},{"min_price":"$1,000.00","max_price":"$30,000.00","terms":[{"apr":15,"loan_type":"interest","installments_count":3},{"apr":15,"loan_type":"interest","installments_count":6},{"apr":15,"loan_type":"interest","installments_count":12}]}],"installments_buyer_prequalification_enabled":false}`}
            ></shopify-payment-terms>
        </Fragment>
    );
};
